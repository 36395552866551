// import React, { useState, useCallback, useRef } from "react";
// import { Grid, Input, Label, Button, Icon} from "semantic-ui-react";
// import PropTypes from 'prop-types';

// const SearchBox = ({ setSearchState, onSearch }) => {
//   const [inputUrl, setInputUrl] = useState("");
//   const fileInputRef = useRef(null);

//   const handleChange = useCallback((e) => {
//     const value = e.target.value;
//     setInputUrl(value);
//     setSearchState(value);
//   }, [setSearchState]);

//   const handleClear = useCallback(() => {
//     setSearchState("");
//     setInputUrl("");
//   }, [setSearchState]);

//   const handleFileChange = useCallback((e) => {
//     const file = e.target.files[0];
//     if (!file) return;

//     const reader = new FileReader();
//     reader.onload = (event) => {
//       setSearchState(event.target.result);
//       setInputUrl("");
//     };
//     reader.readAsDataURL(file);
//   }, [setSearchState]);

//   const handleDrop = useCallback((e) => {
//     e.preventDefault();
//     const file = e.dataTransfer.files[0];
//     if (file && file.type.startsWith('image/')) {
//       const reader = new FileReader();
//       reader.onload = (event) => {
//         setSearchState(event.target.result);
//         setInputUrl("");
//       };
//       reader.readAsDataURL(file);
//     }
//   }, [setSearchState]);

//   return (
//     <Grid stackable>
//       <Grid.Row>
//         <Grid.Column width={12}>
//           <Input
//             fluid
//             value={inputUrl}
//             icon={<Icon name="search" inverted circular  />}
//             iconPosition="left"
//             placeholder=" Ingrese una URL o cargue una imagen local"
//             onChange={handleChange}
//             action={
//               <Button.Group>
//                 <Button icon="delete" onClick={handleClear} />
//                 <Button icon="upload" onClick={() => fileInputRef.current.click()} />
//               </Button.Group>
//             }
//             onDragOver={(e) => e.preventDefault()}
//             onDrop={handleDrop}
//           />
//           <Label pointing="above" className="pointedLabel" style={{ backgroundColor: "#14539A", color: '#f9fafb'}}>
//             Ingrese una URL o arrastre una imagen
//           </Label>
//         </Grid.Column>
//         <Grid.Column width={4} textAlign="center">
//           <input
//             ref={fileInputRef}
//             type="file"
//             accept="image/*"
//             onChange={handleFileChange}
//             style={{ display: "none" }}
//           />
//         </Grid.Column>
//       </Grid.Row>
//     </Grid>
//   );
// };

// SearchBox.propTypes = {
//   setSearchState: PropTypes.func.isRequired,
//   onSearch: PropTypes.func.isRequired,
// };

// export default SearchBox;




import React, { useState, useCallback, useRef } from "react";
import { Grid, Input, Label, Button, Icon} from "semantic-ui-react";
import PropTypes from 'prop-types';

const SearchBox = ({ setSearchState, onSearch }) => {
  const [inputUrl, setInputUrl] = useState("");
  const fileInputRef = useRef(null);
  const inputRef = useRef(null);

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    setInputUrl(value);
    setSearchState(value);
  }, [setSearchState]);

  const handleClear = useCallback(() => {
    setSearchState("");
    setInputUrl("");
  }, [setSearchState]);

  const handleFileChange = useCallback((e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      setSearchState(event.target.result);
      setInputUrl("");
    };
    reader.readAsDataURL(file);
  }, [setSearchState]);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setSearchState(event.target.result);
        setInputUrl("");
      };
      reader.readAsDataURL(file);
    }
  }, [setSearchState]);

  const handlePaste = useCallback((e) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const file = items[i].getAsFile();
        const reader = new FileReader();
        reader.onload = (event) => {
          setSearchState(event.target.result);
          setInputUrl("");
        };
        reader.readAsDataURL(file);
        break;
      }
    }
  }, [setSearchState]);

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={12}>
          <Input
            fluid
            ref={inputRef}
            value={inputUrl}
            icon={<Icon name="search" inverted circular  />}
            iconPosition="left"
            placeholder=" Ingrese una URL o seleccione una imagen (cargar/arrastrar/pegar)"
            onChange={handleChange}
            action={
              <Button.Group>
                <Button icon="delete" onClick={handleClear} />
                <Button icon="upload" onClick={() => fileInputRef.current.click()} />
              </Button.Group>
            }
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
            onPaste={handlePaste}
          />
          <Label pointing="above" className="pointedLabel" style={{ backgroundColor: "#14539A", color: '#f9fafb'}}>
          Suba en este apartado su imagen
          </Label>
        </Grid.Column>
        <Grid.Column width={4} textAlign="center">
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

SearchBox.propTypes = {
  setSearchState: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default SearchBox;