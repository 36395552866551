// import React, { useState, useEffect } from "react";
// import { Button, Grid, GridColumn, Image as SemanticImage, Card, Label } from "semantic-ui-react";
// import ShowPrediction from "./Showprediction";
// import * as tmImage from '@teachablemachine/image';
// import Swal from 'sweetalert2';

// const Preview = ({ searchState }) => {
//     const [predictedData, setPredictedData] = useState({
//         Product: "",
//         Probability: "",
//     });
//     const [model, setModel] = useState(null);
//     const [isModelLoaded, setIsModelLoaded] = useState(false);
//     const [imageHistory, setImageHistory] = useState([]);
//     const [imageCounter, setImageCounter] = useState(0);

//     useEffect(() => {
//         loadModel();
//     }, []);

//     const loadModel = async () => {
//         const URL = "https://teachablemachine.withgoogle.com/models/3-S1t9wTc/";
//         const modelURL = URL + "model.json";
//         const metadataURL = URL + "metadata.json";
        
//         try {
//             const loadedModel = await tmImage.load(modelURL, metadataURL);
//             setModel(loadedModel);
//             setIsModelLoaded(true);
//             console.log('Modelo cargado exitosamente');
//         } catch (error) {
//             console.error('Error al cargar el modelo:', error);
//             Swal.fire({
//                 icon: 'error',
//                 title: 'Error al cargar el modelo',
//                 text: 'Hubo un problema al cargar el modelo. Por favor, recarga la página.',
//             });
//         }
//     };

//     const handlePredict = async () => {
//         if (searchState === "") {
//             Swal.fire({
//                 icon: 'warning',
//                 title: 'Campo vacío',
//                 text: 'Por favor, ingrese una URL o carga una imágen local.',
//             });
//             return;
//         }
    
//         if (!isModelLoaded) {
//             Swal.fire({
//                 icon: 'info',
//                 title: 'Modelo cargando',
//                 text: 'El modelo aún no ha terminado de cargar. Por favor, espere un momento.',
//             });
//             return;
//         }
    
//         Swal.fire({
//             title: 'Cargando...',
//             text: 'Por favor, espere mientras se procesa la imagen.',
//             icon: 'success',
//             showConfirmButton: false,
//             allowOutsideClick: false,
//             didOpen: () => {
//                 Swal.showLoading();
//             }
//         });
//         await new Promise(resolve => setTimeout(resolve, 600));
        
//         try {
//             console.log('Cargando la imagen...', searchState);
//             let img;
//             if (typeof searchState === 'string') {
//                 img = await loadImage(searchState);
//             } else if (searchState instanceof File) {
//                 img = await loadImageFromFile(searchState);
//             } else if (searchState instanceof Blob) {
//                 img = await loadImageFromFile(searchState);
//             } else {
//                 throw new Error('Tipo de searchState no válido');
//             }
    
//             console.log('Imagen cargada, procediendo con la predicción');
//             const predictions = await model.predict(img);
//             console.log('Predicciones obtenidas:', predictions);
    
//             const topPrediction = predictions.reduce((prev, current) => 
//                 (prev.probability > current.probability) ? prev : current
//             );
    
//             setPredictedData({
//                 Product: topPrediction.className,
//                 Probability: (topPrediction.probability * 10).toFixed(2),
//             });

//             setImageCounter(prevCounter => prevCounter + 1);
//             setImageHistory(prevHistory => [{
//                 id: imageCounter + 1,
//                 image: searchState,
//                 prediction: topPrediction.className,
//                 probability: (topPrediction.probability * 100).toFixed(2)
//             }, ...prevHistory]);

//             Swal.close();
//         } catch (error) {
//             console.error('Error detallado:', error);
//             Swal.close();
            
//             if (error.message.includes('Error al cargar la imagen')) {
//                 Swal.fire({
//                     icon: 'error',
//                     title: 'Error de carga',
//                     text: 'No se pudo acceder a la imagen. Por favor, verifica que la URL sea correcta y accesible.',
//                 });
//             } else {
//                 Swal.fire({
//                     icon: 'error',
//                     title: 'Error inesperado',
//                     text: `Ocurrió un error inesperado: ${error.message}`,
//                 });
//             }
//         }
//     };

//     const loadImage = (url) => {
//         return new Promise((resolve, reject) => {
//             const img = new Image();
//             img.crossOrigin = "anonymous";
//             img.onload = () => {
//                 console.log('Imagen cargada exitosamente:', url);
//                 resolve(img);
//             };
//             img.onerror = (e) => {
//                 console.error('Error al cargar la imagen:', url, e);
//                 reject(new Error(`Error al cargar la imagen: ${url}`));
//             };
//             img.src = url;
//         });
//     };

//     const loadImageFromFile = (file) => {
//         return new Promise((resolve, reject) => {
//             const reader = new FileReader();
//             reader.onload = (e) => {
//                 const img = new Image();
//                 img.onload = () => resolve(img);
//                 img.onerror = () => reject(new Error('Error al cargar la imagen del archivo'));
//                 img.src = e.target.result;
//             };
//             reader.onerror = () => reject(new Error('Error al leer el archivo'));
//             reader.readAsDataURL(file);
//         });
//     };

//     const downloadPredictionHistory = () => {
//         if (imageHistory.length === 0) {
//             Swal.fire({
//                 icon: 'info',
//                 title: 'Historial vacío',
//                 text: 'No hay predicciones para descargar.',
//             });
//             return;
//         }

//         const csvContent = [
//             ['ID de Imagen', 'Especie', 'Probabilidad'],
//             ...imageHistory.map(item => [
//                 item.id,
//                 item.prediction,
//                 `${item.probability}%`
//             ])
//         ].map(e => e.join(',')).join('\n');

//         const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
//         const link = document.createElement("a");
//         if (link.download !== undefined) {
//             const url = URL.createObjectURL(blob);
//             link.setAttribute("href", url);
//             link.setAttribute("download", "historial_prediccion_especies.csv");
//             link.style.visibility = 'hidden';
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//         }
//     };

//     return (
//         <Grid>
//             <GridColumn width={10}>
//                 <h2>Previsualización de Imágen</h2>
//                 <SemanticImage src={searchState} size="massive" wrapped />
//                 <br />
//             </GridColumn>
//             <GridColumn width={6}>
//                 <h2>Panel de Predicción</h2>
//                 <Button 
//                     className="predictImagenBtn" 
//                     onClick={handlePredict} 
//                     style={{ backgroundColor: "grey", color: "#f9fafb" }}
//                     disabled={!isModelLoaded}
//                 >
//                     Predecir
//                 </Button>
//                 <br />
//                 <br />
//                 <ShowPrediction predictedData={predictedData} />
//                 <br />
//             </GridColumn>
//             <Grid.Column width={16} style={{ padding: '0 20px' }}>
//                 <br />
//                 <h2>Historial de Imágenes</h2>
//                 <Button 
//                     onClick={downloadPredictionHistory}
//                     style={{ backgroundColor: "grey", color: "#ffffff", marginBottom: '10px' }}
//                 >
//                     Descargar historial
//                 </Button>
//                 <div style={{ 
//                     display: 'grid', 
//                     gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
//                     gap: '10px', 
//                     maxHeight: '400px',
//                     overflowY: 'auto',
//                     width: '100%',
//                     padding: '0 10px'
//                 }}>
//                     {imageHistory.length === 0 ? (
//                         <div style={{ gridColumn: 'span 4', textAlign: 'center', padding: '20px' }}>
//                             <p>El historial de imágenes está vacío.</p>
//                         </div>
//                     ) : (
//                         imageHistory.map((item, index) => (
//                             <Card key={index} style={{ margin: '0', padding: '0' }}>
//                                 <div style={{ 
//                                     height: '200px',
//                                     overflow: 'hidden',
//                                 }}>
//                                     <SemanticImage 
//                                         src={item.image} 
//                                         size="big" 
//                                         wrapped 
//                                         style={{ 
//                                             width: '100%', 
//                                             height: '100%', 
//                                             objectFit: 'cover'
//                                         }}
//                                     />
//                                 </div>
//                                 <Card.Content>
//                                     <Card.Header>{item.prediction}</Card.Header>
//                                     <Card.Meta>
//                                         <Label>{item.probability}%</Label>
//                                         <Label>ID: {item.id}</Label>
//                                     </Card.Meta>
//                                 </Card.Content>
//                             </Card>
                            
//                         ))
//                     )}
//                 </div>
//             </Grid.Column>
//         </Grid>
//     );
// };

// export default Preview;




// import React, { useState, useEffect } from "react";
// import { Button, Grid, GridColumn, Image as SemanticImage, Card, Label, Modal, Header } from "semantic-ui-react";
// import ShowPrediction from "./Showprediction";
// import * as tmImage from '@teachablemachine/image';
// import Swal from 'sweetalert2';

// const Preview = ({ searchState }) => {
//     const [predictedData, setPredictedData] = useState({
//         Product: "",
//         Probability: "",
//     });
//     const [model, setModel] = useState(null);
//     const [isModelLoaded, setIsModelLoaded] = useState(false);
//     const [imageHistory, setImageHistory] = useState([]);
//     const [imageCounter, setImageCounter] = useState(0);
//     const [modalOpen, setModalOpen] = useState(false);

//     useEffect(() => {
//         loadModel();
//     }, []);

//     const loadModel = async () => {
//         const URL = "https://teachablemachine.withgoogle.com/models/3-S1t9wTc/";
//         const modelURL = URL + "model.json";
//         const metadataURL = URL + "metadata.json";
        
//         try {
//             const loadedModel = await tmImage.load(modelURL, metadataURL);
//             setModel(loadedModel);
//             setIsModelLoaded(true);
//             console.log('Modelo cargado exitosamente');
//         } catch (error) {
//             console.error('Error al cargar el modelo:', error);
//             Swal.fire({
//                 icon: 'error',
//                 title: 'Error al cargar el modelo',
//                 text: 'Hubo un problema al cargar el modelo. Por favor, recarga la página.',
//             });
//         }
//     };

//     const handlePredict = async () => {
//         if (searchState === "") {
//             Swal.fire({
//                 icon: 'warning',
//                 title: 'Campo vacío',
//                 text: 'Por favor, ingrese una URL o carga una imágen local.',
//             });
//             return;
//         }
    
//         if (!isModelLoaded) {
//             Swal.fire({
//                 icon: 'info',
//                 title: 'Modelo cargando',
//                 text: 'El modelo aún no ha terminado de cargar. Por favor, espere un momento.',
//             });
//             return;
//         }
    
//         Swal.fire({
//             title: 'Cargando...',
//             text: 'Por favor, espere mientras se procesa la imagen.',
//             icon: 'success',
//             showConfirmButton: false,
//             allowOutsideClick: false,
//             didOpen: () => {
//                 Swal.showLoading();
//             }
//         });
//         await new Promise(resolve => setTimeout(resolve, 600));
        
//         try {
//             console.log('Cargando la imagen...', searchState);
//             let img;
//             if (typeof searchState === 'string') {
//                 img = await loadImage(searchState);
//             } else if (searchState instanceof File) {
//                 img = await loadImageFromFile(searchState);
//             } else if (searchState instanceof Blob) {
//                 img = await loadImageFromFile(searchState);
//             } else {
//                 throw new Error('Tipo de searchState no válido');
//             }
    
//             console.log('Imagen cargada, procediendo con la predicción');
//             const predictions = await model.predict(img);
//             console.log('Predicciones obtenidas:', predictions);
    
//             const topPrediction = predictions.reduce((prev, current) => 
//                 (prev.probability > current.probability) ? prev : current
//             );
    
//             setPredictedData({
//                 Product: topPrediction.className,
//                 Probability: (topPrediction.probability * 10).toFixed(2),
//             });

//             setImageCounter(prevCounter => prevCounter + 1);
//             setImageHistory(prevHistory => [{
//                 id: imageCounter + 1,
//                 image: searchState,
//                 prediction: topPrediction.className,
//                 probability: (topPrediction.probability * 100).toFixed(2)
//             }, ...prevHistory]);

//             Swal.close();
//         } catch (error) {
//             console.error('Error detallado:', error);
//             Swal.close();
            
//             if (error.message.includes('Error al cargar la imagen')) {
//                 Swal.fire({
//                     icon: 'error',
//                     title: 'Error de carga',
//                     text: 'No se pudo acceder a la imagen. Por favor, verifica que la URL sea correcta y accesible.',
//                 });
//             } else {
//                 Swal.fire({
//                     icon: 'error',
//                     title: 'Error inesperado',
//                     text: `Ocurrió un error inesperado: ${error.message}`,
//                 });
//             }
//         }
//     };

//     const loadImage = (url) => {
//         return new Promise((resolve, reject) => {
//             const img = new Image();
//             img.crossOrigin = "anonymous";
//             img.onload = () => {
//                 console.log('Imagen cargada exitosamente:', url);
//                 resolve(img);
//             };
//             img.onerror = (e) => {
//                 console.error('Error al cargar la imagen:', url, e);
//                 reject(new Error(`Error al cargar la imagen: ${url}`));
//             };
//             img.src = url;
//         });
//     };

//     const loadImageFromFile = (file) => {
//         return new Promise((resolve, reject) => {
//             const reader = new FileReader();
//             reader.onload = (e) => {
//                 const img = new Image();
//                 img.onload = () => resolve(img);
//                 img.onerror = () => reject(new Error('Error al cargar la imagen del archivo'));
//                 img.src = e.target.result;
//             };
//             reader.onerror = () => reject(new Error('Error al leer el archivo'));
//             reader.readAsDataURL(file);
//         });
//     };

//     const downloadPredictionHistory = () => {
//         if (imageHistory.length === 0) {
//             Swal.fire({
//                 icon: 'info',
//                 title: 'Historial vacío',
//                 text: 'No hay predicciones para descargar.',
//             });
//             return;
//         }

//         const csvContent = [
//             ['ID de Imagen', 'Especie', 'Probabilidad'],
//             ...imageHistory.map(item => [
//                 item.id,
//                 item.prediction,
//                 `${item.probability}%`
//             ])
//         ].map(e => e.join(',')).join('\n');

//         const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
//         const link = document.createElement("a");
//         if (link.download !== undefined) {
//             const url = URL.createObjectURL(blob);
//             link.setAttribute("href", url);
//             link.setAttribute("download", "historial_prediccion_especies.csv");
//             link.style.visibility = 'hidden';
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//         }
//     };

//     return (
//         <Grid>
//             <GridColumn width={10}>
//                 <Header as={"h1"} style={{ fontSize: 'clamp(10px, 5vw, 25px)' }}>Previsualización de Imágen</Header>
//                 {/* <h2>Previsualización de Imágen</h2> */}
//                 <SemanticImage 
//                     src={searchState} 
//                     size="massive" 
//                     wrapped 
//                     onClick={() => setModalOpen(true)}
//                     style={{ cursor: 'pointer' }}
//                 />
//                 <br />
//             </GridColumn>
//             <GridColumn width={6}>
//                 <Header as={"h1"} style={{ fontSize: 'clamp(10px, 5vw, 25px)' }}>Panel de Predicción</Header>
//                 {/* <h2>Panel de Predicción</h2> */}
//                 <Button 
//                     className="predictImagenBtn" 
//                     onClick={handlePredict} 
//                     style={{ backgroundColor: "grey", color: "#f9fafb" }}
//                     disabled={!isModelLoaded}
//                 >
//                     Predecir
//                 </Button>
//                 <br />
//                 <br />
//                 <ShowPrediction predictedData={predictedData} />
//                 <br />
//             </GridColumn>
//             <Grid.Column width={16} style={{ padding: '0 20px' }}>
//                 <br />
//                 <Header as={"h1"} style={{ fontSize: 'clamp(10px, 5vw, 25px)' }}>Historial de Imágenes</Header>
//                 {/* <h2>Historial de Imágenes</h2> */}
//                 <Button 
//                     onClick={downloadPredictionHistory}
//                     style={{ backgroundColor: "grey", color: "#ffffff", marginBottom: '10px' }}
//                 >
//                     Descargar historial
//                 </Button>
//                 <div style={{ 
//                     display: 'grid', 
//                     gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
//                     gap: '10px', 
//                     maxHeight: '400px',
//                     overflowY: 'auto',
//                     width: '100%',
//                     padding: '0 10px'
//                 }}>
//                     {imageHistory.length === 0 ? (
//                         <div style={{ gridColumn: 'span 4', textAlign: 'center', padding: '20px' }}>
//                             <p>El historial de imágenes está vacío.</p>
//                         </div>
//                     ) : (
//                         imageHistory.map((item, index) => (
//                             <Card key={index} style={{ margin: '0', padding: '0' }}>
//                                 <div style={{ 
//                                     height: '200px',
//                                     overflow: 'hidden',
//                                 }}>
//                                     <SemanticImage 
//                                         src={item.image} 
//                                         size="big" 
//                                         wrapped 
//                                         style={{ 
//                                             width: '100%', 
//                                             height: '100%', 
//                                             objectFit: 'cover'
//                                         }}
//                                     />
//                                 </div>
//                                 <Card.Content>
//                                     <Card.Header>{item.prediction}</Card.Header>
//                                     <Card.Meta>
//                                         <Label>{item.probability}%</Label>
//                                         <Label>ID: {item.id}</Label>
//                                     </Card.Meta>
//                                 </Card.Content>
//                             </Card>
//                         ))
//                     )}
//                 </div>
//             </Grid.Column>
//             <Modal
//                 open={modalOpen}
//                 onClose={() => setModalOpen(false)}
//                 size="large"
//                 style={{
//                     display: 'flex',
//                     alignItems: 'center',
//                     justifyContent: 'center'
//                 }}
//             >
//                 <Modal.Content image style={{
//                     background: 'transparent',
//                     boxShadow: 'none',
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     height: '100%',
//                     width: '100%'
//                 }}>
//                     <SemanticImage 
//                         src={searchState} 
//                         style={{
//                             maxHeight: '90vh',
//                             maxWidth: '90vw',
//                             objectFit: 'contain'
//                         }}
//                     />
//                 </Modal.Content>
//             </Modal>
//         </Grid>
//     );
// };

// export default Preview;





import React, { useState, useEffect } from "react";
import { Button, Grid, GridColumn, Image as SemanticImage, Card, Label, Modal, Header } from "semantic-ui-react";
import ShowPrediction from "./Showprediction";
import * as tmImage from '@teachablemachine/image';
import Swal from 'sweetalert2';

const Preview = ({ searchState }) => {
    const [predictedData, setPredictedData] = useState({
        Product: "",
        Probability: "",
    });
    const [model, setModel] = useState(null);
    const [isModelLoaded, setIsModelLoaded] = useState(false);
    const [imageHistory, setImageHistory] = useState([]);
    const [imageCounter, setImageCounter] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        loadModel();
    }, []);

    const loadModel = async () => {
        const URL = "https://teachablemachine.withgoogle.com/models/3-S1t9wTc/";
        const modelURL = URL + "model.json";
        const metadataURL = URL + "metadata.json";
        
        try {
            const loadedModel = await tmImage.load(modelURL, metadataURL);
            setModel(loadedModel);
            setIsModelLoaded(true);
            console.log('Modelo cargado exitosamente');
        } catch (error) {
            console.error('Error al cargar el modelo:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error al cargar el modelo',
                text: 'Hubo un problema al cargar el modelo. Por favor, recarga la página.',
            });
        }
    };

    const handlePredict = async () => {
        if (searchState === "") {
            Swal.fire({
                icon: 'warning',
                title: 'Campo vacío',
                text: 'Por favor, ingrese una URL o cargue una imagen local.',
            });
            return;
        }
    
        if (!isModelLoaded) {
            Swal.fire({
                icon: 'info',
                title: 'Modelo cargando',
                text: 'El modelo aún no ha terminado de cargar. Por favor, espere un momento.',
            });
            return;
        }
    
        Swal.fire({
            title: 'Cargando...',
            text: 'Por favor, espere mientras se procesa la imagen.',
            icon: 'success',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        await new Promise(resolve => setTimeout(resolve, 600));
        
        try {
            console.log('Cargando la imagen...', searchState);
            let img;
            if (typeof searchState === 'string') {
                img = await loadImage(searchState);
            } else if (searchState instanceof File) {
                img = await loadImageFromFile(searchState);
            } else if (searchState instanceof Blob) {
                img = await loadImageFromFile(searchState);
            } else {
                throw new Error('Tipo de searchState no válido');
            }
    
            console.log('Imagen cargada, procediendo con la predicción');
            const predictions = await model.predict(img);
            console.log('Predicciones obtenidas:', predictions);
    
            const topPrediction = predictions.reduce((prev, current) => 
                (prev.probability > current.probability) ? prev : current
            );
    
            setPredictedData({
                Product: topPrediction.className,
                Probability: (topPrediction.probability * 100).toFixed(2),
            });

            setImageCounter(prevCounter => prevCounter + 1);
            setImageHistory(prevHistory => [{
                id: imageCounter + 1,
                image: searchState,
                prediction: topPrediction.className,
                probability: (topPrediction.probability * 100).toFixed(2)
            }, ...prevHistory]);

            Swal.close();
        } catch (error) {
            console.error('Error detallado:', error);
            Swal.close();
            
            if (error.message.includes('Error al cargar la imagen')) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error de carga',
                    text: 'No se pudo acceder a la imagen. Por favor, verifique que la URL sea correcta y accesible.',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error inesperado',
                    text: `Ocurrió un error inesperado: ${error.message}`,
                });
            }
        }
    };

    const loadImage = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.onload = () => {
                console.log('Imagen cargada exitosamente:', url);
                resolve(img);
            };
            img.onerror = (e) => {
                console.error('Error al cargar la imagen:', url, e);
                reject(new Error(`Error al cargar la imagen: ${url}`));
            };
            img.src = url;
        });
    };

    const loadImageFromFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => resolve(img);
                img.onerror = () => reject(new Error('Error al cargar la imagen del archivo'));
                img.src = e.target.result;
            };
            reader.onerror = () => reject(new Error('Error al leer el archivo'));
            reader.readAsDataURL(file);
        });
    };

    const downloadPredictionHistory = () => {
        if (imageHistory.length === 0) {
            Swal.fire({
                icon: 'info',
                title: 'Historial vacío',
                text: 'No hay predicciones para descargar.',
            });
            return;
        }

        const csvContent = [
            ['ID de Imagen', 'Especie', 'Probabilidad'],
            ...imageHistory.map(item => [
                item.id,
                item.prediction,
                `${item.probability}%`
            ])
        ].map(e => e.join(',')).join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "historial_prediccion_especies.csv");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const generatePredictionHistoryHTML = () => {
        if (imageHistory.length === 0) {
            Swal.fire({
                icon: 'info',
                title: 'Historial vacío',
                text: 'No hay predicciones para mostrar.',
            });
            return null;
        }

        const htmlContent = `
            <html>
            <head>
                <title>Historial de Predicción de Especies</title>
                <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; }
                    th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                    th { background-color: #f2f2f2; }
                    h1 { color: #333; }
                    .image-cell { width: 100px; height: 100px; }
                    .image-cell img { width: 100%; height: 100%; object-fit: cover; }
                </style>
            </head>
            <body>
                <h1>Historial de Predicción de Especies</h1>
                <p>Fecha: ${new Date().toLocaleDateString()}</p>
                <table>
                    <thead>
                        <tr>
                            <th>ID de Imagen</th>
                            <th>Imagen</th>
                            <th>Especie</th>
                            <th>Probabilidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${imageHistory.map(item => `
                            <tr>
                                <td>${item.id}</td>
                                <td class="image-cell"><img src="${item.image}" alt="Imagen ${item.id}"></td>
                                <td>${item.prediction}</td>
                                <td>${item.probability}%</td>
                            </tr>
                        `).join('')}
                    </tbody>
                </table>
            </body>
            </html>
        `;

        return htmlContent;
    };

    const openPredictionHistoryInNewWindow = () => {
        const htmlContent = generatePredictionHistoryHTML();
        if (htmlContent) {
            const newWindow = window.open('', '_blank');
            newWindow.document.write(htmlContent);
            newWindow.document.close();
            newWindow.focus();
        }
    };

    return (
        <Grid>
            <GridColumn width={10}>
                <Header as="h1" style={{ fontSize: 'clamp(10px, 5vw, 25px)' }}>Previsualización de Imagen</Header>
                <SemanticImage 
                    src={searchState} 
                    size="massive" 
                    wrapped 
                    onClick={() => setModalOpen(true)}
                    style={{ cursor: 'pointer' }}
                />
                <br />
            </GridColumn>
            <GridColumn width={6}>
                <Header as="h1" style={{ fontSize: 'clamp(10px, 5vw, 25px)' }}>Panel de Predicción</Header>
                <Button 
                    className="predictImagenBtn" 
                    onClick={handlePredict} 
                    style={{ backgroundColor: "grey", color: "#f9fafb" }}
                    disabled={!isModelLoaded}
                >
                    Predecir
                </Button>
                <br />
                <br />
                <ShowPrediction predictedData={predictedData} />
                <br />
            </GridColumn>
            <Grid.Column width={16} style={{ padding: '0 20px' }}>
                <br />
                <Header as="h1" style={{ fontSize: 'clamp(10px, 5vw, 25px)' }}>Historial de Imágenes</Header>
                <Button 
                    onClick={downloadPredictionHistory}
                    style={{ backgroundColor: "grey", color: "#ffffff", marginBottom: '10px', marginRight: '10px' }}
                >
                    Descargar CSV
                </Button>
                <Button 
                    onClick={openPredictionHistoryInNewWindow}
                    style={{ backgroundColor: "grey", color: "#ffffff", marginBottom: '10px' }}
                >
                    PDF/Impresión
                </Button>
                <div style={{ 
                    display: 'grid', 
                    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                    gap: '10px', 
                    maxHeight: '400px',
                    overflowY: 'auto',
                    width: '100%',
                    padding: '0 10px'
                }}>
                    {imageHistory.length === 0 ? (
                        <div style={{ gridColumn: 'span 4', textAlign: 'center', padding: '20px' }}>
                            <p>El historial de imágenes está vacío.</p>
                        </div>
                    ) : (
                        imageHistory.map((item, index) => (
                            <Card key={index} style={{ margin: '0', padding: '0' }}>
                                <div style={{ 
                                    height: '200px',
                                    overflow: 'hidden',
                                }}>
                                    <SemanticImage 
                                        src={item.image} 
                                        size="big" 
                                        wrapped 
                                        style={{ 
                                            width: '100%', 
                                            height: '100%', 
                                            objectFit: 'cover'
                                        }}
                                    />
                                </div>
                                <Card.Content>
                                    <Card.Header>{item.prediction}</Card.Header>
                                    <Card.Meta>
                                        <Label>{item.probability}%</Label>
                                        <Label>ID: {item.id}</Label>
                                    </Card.Meta>
                                </Card.Content>
                            </Card>
                        ))
                    )}
                </div>
            </Grid.Column>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                size="large"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Modal.Content image style={{
                    background: 'transparent',
                    boxShadow: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}>
                    <SemanticImage 
                        src={searchState} 
                        style={{
                            maxHeight: '90vh',
                            maxWidth: '90vw',
                            objectFit: 'contain'
                        }}
                    />
                </Modal.Content>
            </Modal>
        </Grid>
    );
};

export default Preview;