import React from 'react';
import CustomSidebar from './features/SideExample';
import { Routes, Route } from 'react-router-dom';
import ImagePredictionDashBoard from './ImagePredictionDashBoard';
import InfoComponent from './features/info';
import EnvivoComponent from './features/vivo';
import EspeciesComponent from './features/especies';
import WahooComponent from './features/infoespecies/wahoo';
import { PatasecaComponent } from './features/infoespecies/pataseca';
import { BarrileteComponent } from './features/infoespecies/barrilete';
import { BonitoComponent } from './features/infoespecies/bonito';
import { SierraComponent } from './features/infoespecies/sierra';
import { BotellaComponent } from './features/infoespecies/botella';
import { AletaComponent } from './features/infoespecies/aletamarilla';
import { PatudoComponent } from './features/infoespecies/patudo';
import LoginForm from './Sesion' 
import EnvivoComponentCell from './features/vivocell';


function App() {
  return (
    <div>
      <CustomSidebar />
      <Routes>
        <Route exact path="/" element={<LoginForm />} />
        <Route exact path="/dashboard" element={<ImagePredictionDashBoard />} />
        <Route exact path="/info" element={<InfoComponent />} />
        <Route exact path="/envivo" element={<EnvivoComponent />} />
        <Route exact path="/envivocell" element={<EnvivoComponentCell />} />
        <Route exact path="/especies" element={<EspeciesComponent />} />
        <Route exact path="/especies/wahoo" element={<WahooComponent />} />
        <Route exact path="/especies/pataseca" element={<PatasecaComponent />} />
        <Route exact path="/especies/bonitobarrilete" element={<BarrileteComponent />} />
        <Route exact path="/especies/bonito" element={<BonitoComponent />} />
        <Route exact path="/especies/sierra" element={<SierraComponent />} />
        <Route exact path="/especies/botellagrande" element={<BotellaComponent />} />
        <Route exact path="/especies/aletaamarilla" element={<AletaComponent />} />
        <Route exact path="/especies/patudo" element={<PatudoComponent />} />
        {/* <Route path="/sesion" element={<LoginForm />} /> */}

      </Routes>
    </div>
  );
}

export default App;





